<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 120px">
          <a-input
              class="input"
              v-model="queryParams.uid"
              placeholder="用户ID"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 120px">
          <a-select
              class="input"
              v-model="queryParams.userType"
              allowClear
              placeholder="用户类型"
              @change="search(1)"
          >
            <a-select-option
                v-for="item in fansUserType"
                :value="item.value"
                :key="item.value"
            >{{ item.label }}
            </a-select-option
            >
          </a-select>
        </div>
        <div class="one-item" style="width: 120px">
          <a-select
              class="input"
              v-model="queryParams.authFlag"
              allowClear
              placeholder="认证状态"
              @change="search(1)"
          >
            <a-select-option
                v-for="item in authFlagList"
                :value="item.value"
                :key="item.value"
            >{{ item.label }}
            </a-select-option
            >
          </a-select>
        </div>
        <div class="one-item" style="width: 120px">
          <a-select
              class="input"
              v-model="queryParams.helpAuth"
              allowClear
              placeholder="超级账户"
              @change="search(1)"
          >
            <a-select-option
                v-for="item in dict.yesNo"
                :value="item.value"
                :key="item.value"
            >{{ item.label }}
            </a-select-option
            >
          </a-select>
        </div>
        <div class="one-item" style="width: 120px;padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="用户名"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 120px;padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.company"
              placeholder="公司名称"
              allowClear
          />
        </div>

        <div class="one-item" style="width: 120px">
          <a-select
              class="input"
              v-model="queryParams.type"
              allowClear
              placeholder="账户类型"
              @change="search(1)"
          >
            <a-select-option
                v-for="item in dict['userAccountType']"
                :value="item.value"
                :key="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <!-- <div class="one-item" style="padding-left: 10px">
          <a-input
            class="input"
            v-model="queryParams.owner"
            placeholder=""
            allowClear
          />
        </div> -->
        <div class="one-item" style="width: 120px;padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.mobile"
              placeholder="手机号码"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 140px;padding-left: 10px">
          <a-checkbox
              class="input"
              v-model="queryParams.idCardImage"
              placeholder="存在身份证图片"
              @change="search(1)"
          >存在身份证图片
          </a-checkbox>
        </div>
        <div class="one-item" style="width: 120px;padding-left: 10px">
          <a-checkbox
              class="input"
              v-model="queryParams.vipFlagBool"
              placeholder="是否VIP"
              @change="search(1)"
          >是否VIP
          </a-checkbox>
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="uid"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="text.avatar" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(text.avatar || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(text.avatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="authStatus" slot-scope="text, record">
        <div title="点击审核" @click.stop="auditUser(record)" style="cursor: pointer">
          <a-tag :color="record.authFlag | dictName('authStatus', 'color')" style="cursor: pointer">
            <span v-if="record.authFlag==1">去审核</span>
            <span v-else>{{ record.authFlag| dictName("authStatus") }}</span>
          </a-tag>
        </div>
      </template>

      <template slot="userType" slot-scope="text, record">
        <a-tag :color="record.userType | dictName('fansUserType', 'color')">
          {{ record.userType| dictName("fansUserType") }}
        </a-tag>
      </template>
      <template slot="regFrom" slot-scope="text, record">
        <a-tag :color="record.regFrom | dictName('regFrom', 'color')">{{
            record.regFrom | dictName("regFrom")
          }}
        </a-tag>
      </template>
      <template slot="company" slot-scope="text, record">
        <div><small>{{ text }}</small></div>
        <div><small>{{ record.companyInfo && record.companyInfo.bizCreditNo }}</small></div>
      </template>
      <template slot="helpStatus" slot-scope="text, record">
        <a-switch :default-checked="record.helpAuth==1" @change="changeUserHelpAuth(record)" :loading="showLoading"/>
        <!--        <a-tag :color="record.helpAuth | dictName('validStatus', 'color')">{{-->
        <!--            record.helpAuth | dictName("validStatus")-->
        <!--          }}-->
        <!--        </a-tag>-->
      </template>

      <template slot="tagStr" slot-scope="text, record">
        <div title="点击修改" @click.stop="modifyTag(record)" style="cursor: pointer">
          <a-tag v-if="record.tagStr" color="#FF9900" v-for="one in (record.tagStr||'').split(/,/)" :key="one"
                 mode="dark" style="cursor: pointer">
            {{
              one
            }}
          </a-tag>
          <a-tag v-else color="rgb(24, 144, 255)" mode="dark" style="cursor: pointer">
            去修改
          </a-tag>
        </div>
      </template>
      <template slot="score" slot-scope="text, record">
        <div style="white-space: nowrap">
          <span style="color:#FFF;background-color: #FF9900;padding:2px 5px;">
            {{
              record.score || 0
            }}
          </span> <span style="color:#FFF;background-color: #52c41a;padding:2px 5px;">{{
            record.availableScore || 0
          }}
        </span>
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-switch :default-checked="record.status==1" @change="changeUserState(record)" :loading="showLoading"/>
      </template>
      <template slot="vipFlag" slot-scope="text, record">
        <a-switch :default-checked="record.vipFlag==1" @change="changevipFlag(record)" :loading="showLoading"/>
      </template>
      <template slot="bizLicense" slot-scope="text, record">
        <div v-if="record.bizLicense" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.bizLicense || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.bizLicense || '').split(',')"
          />
        </div>
        <div v-else style="color: #cdcdcd">无图片信息</div>
      </template>
      <template slot="lawerImage" slot-scope="text, record">
        <div v-if="record.lawerImage" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.lawerImage || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.lawerImage || '').split(',')"
          />
        </div>
        <div v-else style="color: #cdcdcd">无图片信息</div>
      </template>
      <template slot="images2" slot-scope="text, record">
        <div v-if="text.bizHeadLogo" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(text.bizHeadLogo || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(text.bizHeadLogo || '').split(',')"
          />
        </div>
        <div v-else style="color: #cdcdcd">无图片信息</div>
      </template>
      <template slot="images3" slot-scope="text, record">
        <div v-if="text.selfImage" class="img-list">
          <el-image
              :src="(text.selfImage || '') | fullImgPath"
              style="width: 40px; height: 40px"
              fit="contain"
              :preview-src-list="(text.selfImage || '').split(',')"
          />
        </div>
        <div v-else style="color: #cdcdcd">无图片信息</div>
      </template>
      <template slot="images4" slot-scope="text, record">
        <div v-if="text.idCardImage" class="img-list">
          <el-image
              :src="(text.idCardImage || '') | fullImgPath"
              style="width: 40px; height: 40px"
              fit="contain"
              :preview-src-list="(text.idCardImage || '').split(',')"
          />
        </div>
        <div v-else style="color: #cdcdcd">无图片信息</div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown
            v-hasAnyPermission="'user:view', 'user:edit', 'user:audit'"
        >
          <a-menu slot="overlay">
            <a-menu-item
                key="auth"
                v-hasPermission="'user:audit'"
                :style="{ color: textColor }"
                @click="handleAction($event, record)"
            >
              <a-icon type="check"></a-icon>
              审核
            </a-menu-item>
            <a-menu-divider/>
            <!--            <a-menu-item-->
            <!--                key="setting"-->
            <!--                :style="{ color: textColor }"-->
            <!--                v-hasPermission="'user:edit'"-->
            <!--                @click="handleAction($event, record)"-->
            <!--            >-->
            <!--              <a-icon type="setting"/>-->
            <!--              状态配置-->
            <!--              &lt;!&ndash; {{ record.helpAuth == 1 ? "取消协助权限" : "允许协助权限" }} &ndash;&gt;-->
            <!--            </a-menu-item>-->
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
    <user-audit-modal ref="userAuditModal" @success="editSuccess"/>
    <setting-modal ref="settingModal" @success="editSuccess"/>
  </a-card>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import userAuditModal from "./userAuditModal.vue";
import settingModal from "./settingModal.vue";
import {DICT} from "@/utils/dict";
import {MessageBox} from 'element-ui';

export default {
  name: "UserList",
  mixins: [ListMixin],
  components: {userAuditModal, settingModal},
  data() {
    return {
      listGetUrl: "user/listByAdmin",
      showLoading: false,
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    authFlagList() {
      return DICT['authStatus']
    },
    fansUserType() {
      return DICT['fansUserType']
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "uid",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'uid' && sortedInfo.order
        },
        {
          title: "头像",
          align: "center",
          width: 60,
          // dataIndex: "avatar",
          scopedSlots: {customRender: "images"},
        },
        {
          title: "用户名",
          width: 150,
          align: "center",
          dataIndex: "username",
        },
        {
          title: "用户类型",
          width: 80,
          align: "center",
          dataIndex: "userType",
          scopedSlots: {customRender: "userType"},
        },
        {
          title: "用户级别",
          width: 100,
          align: "center",
          dataIndex: "userLevel",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'userLevel' && sortedInfo.order
        },
        {
          title: "手机号",
          width: 100,
          align: "center",
          dataIndex: "mobile",
        },
        {
          title: "激活状态",
          width: 80,
          dataIndex: "status",
          align: "center",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "注册来源",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "regFrom"},
        },
        {
          title: "用户积分",
          width: 100,
          align: "center",
          dataIndex: "score",
          sorter: true,
          scopedSlots: {customRender: "score"},
          sortOrder: sortedInfo.columnKey === 'score' && sortedInfo.order
        },
        {
          title: "帖子数",
          width: 100,
          align: "center",
          dataIndex: "postCount",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'postCount' && sortedInfo.order
        },
        {
          title: "认证状态",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "authStatus"},
        },
        {
          title: "头衔",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "tagStr"},
        },
        {
          title: "是否VIP",
          width: 80,
          align: "center",
          dataIndex: "vipFlag",
          scopedSlots: {customRender: "vipFlag"},
        },
        {
          title: "VIP日期",
          width: 120,
          align: "center",
          dataIndex: "vipValidDate",
        },
        {
          title: "超级账户",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "helpStatus"},
        },
        {
          title: "公司|律所名称",
          align: "center",
          width: 200,
          ellipsis: true,
          dataIndex: "company",
          scopedSlots: {customRender: "company"},
        },
        {
          title: "身份证照片",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "images4"},
        },
        {
          title: "营业执照",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "bizLicense"},
        },
        {
          title: "公司照片(门头照)",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "images2"},
        },
        {
          title: "律师资格证照片",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "lawerImage"},
        },
        {
          title: "律师执业时间",
          align: "center",
          width: 100,
          dataIndex: "serviceDate",
        },
        {
          title: "注册时间",
          width: 150,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "地址",
          align: "center",
          width: 230,
          ellipsis: true,
          // scopedSlots: { customRender: "address" },
          customRender: (text, row, index) => {
            let str =
                (row.province || "") +
                (row.city || "") +
                (row.area || "") +
                (row.town || "") +
                (row.address || "");
            return str;
          },
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
      ];
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    editSuccess(operate) {
      this.search();
      if (!operate) {
        this.$message.success("状态配置成功");
        return;
      }
      if (operate == 1) {
        this.$message.success("审核认证成功");
      } else {
        this.$message.error("审核认证不成功");
      }
    },
    changeUserState(record) {
      let that = this;
      let data = {
        uid: record.uid,
        status: record.status == 1 ? 0 : 1,
      };
      that.showLoading = true;
      that.$post("user/updateStatus", data).then(() => {
        that.showLoading = false;
        let mes =
            record.status != 1
                ? "ID：" + record.uid + "用户已激活"
                : "ID：" + record.uid + "用户已禁用";
        that.$message.success(mes);
        this.search();
      });
    },
    changeUserHelpAuth(record) {
      let that = this;
      let data = {
        uid: record.uid,
        helpAuth: record.helpAuth == 1 ? 0 : 1,
      };
      that.showLoading = true;
      that.$post("user/updateHelpAuth", data).then(() => {
        that.showLoading = false;
        let mes =
            record.helpAuth != 1
                ? "ID：" + record.uid + "用户切换为超级账户"
                : "ID：" + record.uid + "用户切换为普通账户";
        that.$message.success(mes);
        this.search();
      });
    },
    changevipFlag(record){
      let that = this;
      let data = {
        id: record.uid,
        fchanged: record.vipFlag == 1 ? 0 : 1,
      };
      that.showLoading = true;
      that.$post("sysuser/updatevipFlag", data).then(() => {
        that.showLoading = false;
        let mes =
            record.vipFlag != 1
                ? "ID：" + record.uid + "切换成vip"
                : "ID：" + record.uid + "取消vip";
        that.$message.success(mes);
        this.search();
      });
    },
    auditUser(record) {
      // if (record.authFlag != 1) {
      //   // this.$message.success("该用户已经认证完成，无需再审核");
      //   return;
      // }

      if (this.$helper.hasPermission('user:audit')) {
        this.$refs.userAuditModal.showModal(record);
      } else {
        this.$message.warn("对不起，您没有审核权限")
      }
    },
    modifyTag(record) {
      const that = this;

      MessageBox.prompt('请输入用户标签（律师头衔）', '修改用户标签、头衔', {
        inputValue: record.tagStr,
        inputPlaceholder: '输入用户标签',
      }).then(({value}) => {
        if (value !== record.tagStr) {
          that.showLoading = true;
          that.$post("user/updateTag", {uid: record.uid, tagStr: value}).then(() => {
            that.showLoading = false;
            that.$message.success("标签修改成功");
            this.search();
          });
        }
      })
    },
    handleAction(e, row) {
      switch (e.key) {
        case "auth":
          if (row.authFlag == 2) {
            this.$message.success("该用户已经认证完成，无需再审核");
            return;
          }
          // if (row.authFlag == 0) {
          //   this.$message.success("该用户资料未完善，不支持审核");
          //   return;
          // }
          this.$refs.userAuditModal.showModal(row);
          break;
        case "setting":
          this.$refs.settingModal.showModal(row);
          break;
      }
    },
    beforeFetch(params) {
      params.vipFlag =  this.queryParams.vipFlagBool ? 1 : undefined
    }
  },
};
</script>

<style lang="less" scoped>
.textClick {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
